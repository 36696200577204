import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {Container, Grid, Menu} from 'semantic-ui-react';
import {useStaticQuery, graphql} from 'gatsby';

import './layout.css';
import favicon from '../images/favicon.ico';

import 'semantic-ui-less/semantic.less';
import {Link} from 'gatsby';

const LinkedItem = ({children, ...props}) => (
  <Menu.Item as={Link} activeClassName="active" {...props}>
    {children}
  </Menu.Item>
);

const Layout = ({children, style}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div
        style={{
          background: '#FFFFFF',
          margin: `0 auto`,
          // padding: `0 1.0875rem 1.45rem`,
          minHeight: '100vh',
          ...style,
        }}
      >
        <link rel="icon" href={favicon} />
        <main>{children}</main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
